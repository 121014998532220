import React from 'react';

import { AboutInformationStyle } from './styles';

const AboutInformation: React.FC = () => {
  return (
    <AboutInformationStyle>
      <div className="title">
        <h2>About Us</h2>
      </div>
      <p>
        Michel and Lucas met while working at Mobjoy on 2011, pioneer on mobile
        games in Brazil. A great friendship was formed and lasts even after they
        are living in different cities after the closure of the company.
      </p>
      <p>
        After years and years playing online games and talking about working
        together, they finally decided to get back and starting a new mobile
        game company called Rusty Harpy. In 2019 Rusty Harpy starts its first
        title, with an already know name “Road Warrior”.
      </p>
    </AboutInformationStyle>
  );
};

export default AboutInformation;

import React from 'react';
import { Grid } from 'react-flexbox-grid';

import { FooterStyle } from './styles';

const Footer: React.FC = () => {
  return (
    <FooterStyle>
      <Grid>
        <p>© 2021 Powered by Rusty Harpy</p>
      </Grid>
    </FooterStyle>
  );
};

export default Footer;

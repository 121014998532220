import styled from '@emotion/styled';

import { Breakpoints, Colors } from '../../styles/theme';

export const GamesStyle = styled.section`
  position: relative;
  background-image: url(./images/games/road-warrior/background.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 100px 0;

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(12, 17, 24, 0.85);
  }

  .games-row {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
  }

  > .row {
    margin: 0;

    > div {
      padding: 0;
    }
  }

  .row {
    > div {
      z-index: 5;
    }
  }

  @media (max-width: ${Breakpoints.sm}px) {
    padding: 30px 0;
  }
`;

export const GamesSliderStyle = styled.div`
  height: 400px;
  margin-bottom: 20px;
  padding: 0 80px;

  .slider {
    position: relative;
    height: 100%;

    .logo {
      position: absolute;
      height: 100%;
      width: 100%;

      z-index: 8;

      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-align: center;
      align-items: center;

      img {
        vertical-align: middle;
        margin-left: 30px;
        height: 70%;
        width: auto;
      }

      @media (max-width: ${Breakpoints.xs}px) {
        display: none;
      }
    }
  }

  @media (max-width: ${Breakpoints.el}px) {
    height: 300px;
  }

  @media (max-width: ${Breakpoints.lg}px) {
    height: 220px;
  }

  @media (max-width: ${Breakpoints.md}px) {
    height: 180px;
    padding: 0;
  }

  @media (max-width: ${Breakpoints.sm}px) {
    height: 150px;
  }

  @media (max-width: ${Breakpoints.xs}px) {
    height: 100px;
  }

  .aws-btn {
    --slider-height-percentage: 36%;
    --slider-transition-duration: 770ms;
    --organic-arrow-thickness: 5px;
    --organic-arrow-border-radius: 30px;
    --organic-arrow-height: 20px;
    --organic-arrow-color: ${Colors.white};
    --control-button-width: 40px;
    --control-button-height: 100%;
    --loader-bar-color: ${Colors.blue};
    --loader-bar-height: 6px;
  }
`;

export const GamesInformationStyle = styled.div`
  padding-left: 80px;

  h2 {
    margin-bottom: 20px;
    font-size: 32px;
    color: ${Colors.white};
    margin: 0 0 10px;
    font-weight: 600;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: -0.04em;
  }

  h4 {
    color: ${Colors.graySecondary};

    @media (max-width: ${Breakpoints.sm}px) {
      text-align: center;
    }
  }

  p {
    color: ${Colors.whiteSecondary};
    margin-bottom: 30px;
  }

  .store-icons {
    margin-bottom: 20px;

    @media (max-width: ${Breakpoints.sm}px) {
      text-align: center;
    }

    a {
      margin-right: 10px;

      &:hover {
        opacity: 0.6;
      }

      img {
        width: 140px;

        &:last-child {
          margin-right: 0;
        }

        @media (max-width: ${Breakpoints.lg}px) {
          width: 100px;
        }

        @media (max-width: ${Breakpoints.sm}px) {
          width: 160px;
          margin: 0 5px;
        }

        @media (max-width: ${Breakpoints.xs}px) {
          width: 125px;
        }
      }

      @media (max-width: ${Breakpoints.sm}px) {
        margin: 0 5px;
      }
    }
  }

  @media (max-width: ${Breakpoints.md}px) {
    padding-left: 0;
  }
`;

export const GamesSpecificationsStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 80px;

  margin-right: -15px;
  margin-left: -15px;

  .specification-col {
    padding: 15px;
  }

  > div {
    padding: 0;

    .row {
      margin: 0;
    }
  }

  @media (max-width: ${Breakpoints.md}px) {
    padding-right: 0;
  }
`;

export const GamesSpecificationsItemStyle = styled.div`
  background-color: rgba(255, 255, 255, 0.03);
  padding: 30px 20px;
  transition: all 0.2s ease-in-out;

  h3 {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }

  p {
    color: ${Colors.graySecondary};
    font-size: 13px;
    min-height: 80px;
    margin: 0;

    @media (max-width: ${Breakpoints.el}px) {
      min-height: 92px;
    }

    @media (max-width: ${Breakpoints.lg}px) {
      min-height: 115px;
    }

    @media (max-width: ${Breakpoints.md}px) {
      min-height: 40px;
    }
  }

  svg {
    font-size: 32px;
    color: ${Colors.orange};
    margin-bottom: 15px;
  }
`;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import socialMediaData from './socialMedias';

import { PresentationContentStyle } from './styles';

const PresentationContent: React.FC = () => {
  return (
    <PresentationContentStyle>
      <img src="./images/logo.png" alt="Rusty Harpy" />
      <ul>
        {socialMediaData.map((data) => (
          <li key={data.name}>
            <a href={data.url} target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={data.icon} />
            </a>
          </li>
        ))}
      </ul>
    </PresentationContentStyle>
  );
};

export default PresentationContent;

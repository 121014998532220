import React from 'react';

import { GamesInformationStyle } from './styles';

const GamesInformation: React.FC<{
  name: string;
  resume: string;
  store: {
    googlePlay?: string;
    appStore?: string;
  };
}> = ({ name, resume, store }) => {
  return (
    <GamesInformationStyle>
      <h2>{name}</h2>
      <p>
        {resume
          .split(/(?:\r\n|\r|\n)/g)
          .map((text, index) => ({ text, index }))
          .map(({ text, index }) => (
            <React.StrictMode key={index}>
              {text}
              <br />
            </React.StrictMode>
          ))}
      </p>
      <div className="store-icons">
        <h4>Download for free now on:</h4>
        {store.appStore && (
          <a href={store.appStore} target="_blank" rel="noopener noreferrer">
            <img src="./images/store/app-store.png" alt="AppStore" />
          </a>
        )}
        {store.googlePlay && (
          <a href={store.googlePlay} target="_blank" rel="noopener noreferrer">
            <img src="./images/store/google-play.png" alt="Google Play" />
          </a>
        )}
      </div>
    </GamesInformationStyle>
  );
};

export default GamesInformation;

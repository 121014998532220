import {
  faCarCrash,
  faGlobeAmericas,
  faFillDrip,
  faMedal,
  faMusic,
  faMobileAlt,
  faGem,
  faSkull,
  faUnlock,
  faHandPointDown,
  faGhost,
} from '@fortawesome/free-solid-svg-icons';

export default [
  {
    id: '2',
    name: 'Finger Blade',
    resume:
      'Dive into the vibrant world of Finger Blade, where your finger becomes the ultimate weapon! With its cute and colorful art style, this mobile game invites you to unleash your inner warrior as you slash through waves of adorable yet fierce enemies.\r\n\r\nIn Finger Blade, you control a magical sword with simple swipe gestures. Glide your finger across the screen to perform swift slashes, dodging enemy bullets and unleashing powerful combos. The intuitive controls make it easy to pick up and play, while the fast-paced action keeps you on your toes!',
    store: {
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.furylabs.fingerblade',
    },
    logo: './images/games/finger-blade/logo.png',
    backgorund: './images/games/finger-blade/background.jpg',
    images: [
      './images/games/finger-blade/finger-blade-1.jpg',
      './images/games/finger-blade/finger-blade-2.jpg',
      './images/games/finger-blade/finger-blade-3.jpg',
      './images/games/finger-blade/finger-blade-4.jpg',
      './images/games/finger-blade/finger-blade-5.jpg',
      './images/games/finger-blade/finger-blade-6.jpg',
      './images/games/finger-blade/finger-blade-7.jpg',
      './images/games/finger-blade/finger-blade-8.jpg',
    ],
    specifications: [
      {
        title: 'Swipe to Slash',
        text: 'Use intuitive finger swipes to attack enemies and dodge incoming projectiles.',
        icon: faHandPointDown,
      },
      {
        title: 'Colorful World',
        text: 'Explore beautifully designed levels filled with charming characters and whimsical environments.',
        icon: faGlobeAmericas,
      },
      {
        title: 'Collect and Upgrade',
        text: 'Gather a variety of items to enhance your abilities and upgrade your sword. Merge items to create even more powerful gear!',
        icon: faGem,
      },
      {
        title: 'Challenging Enemies',
        text: 'Face off against a diverse array of enemies, each with unique attack patterns and strategies.',
        icon: faGhost,
      },
      {
        title: 'Boss Battles',
        text: 'Test your skills in epic boss fights that will challenge your reflexes and timing.',
        icon: faSkull,
      },
      {
        title: 'Unlockable Content',
        text: 'Discover new swords and abilities as you progress, keeping the gameplay fresh and exciting.',
        icon: faUnlock,
      },
    ],
  },
  {
    id: '1',
    name: 'Road Warrior',
    resume:
      'Road Warrior is set in a post-apocalyptic world where drivers have customized cars armed with arsenals of weapons to give each racer a competitive advantage.\r\n\r\nThe goal is to win the race by successfully maneuvering the racetrack with numerous stunts, avoid being blown up, use your weapons to get ahead, and win the race.\r\n\r\nPlayers get to unlock and race customizable cars that can be equipped with deadly weapons and blow up their competitors.',
    store: {
      appStore: 'https://apps.apple.com/app/road-warrior-racing/id1551501412',
      googlePlay:
        'https://play.google.com/store/apps/details?id=com.rustyharpy.roadwarrior',
    },
    logo: './images/games/road-warrior/logo.png',
    backgorund: './images/games/road-warrior/background.jpg',
    images: [
      './images/games/road-warrior/road-warrior-1.jpg',
      './images/games/road-warrior/road-warrior-2.jpg',
      './images/games/road-warrior/road-warrior-3.jpg',
      './images/games/road-warrior/road-warrior-4.jpg',
    ],
    specifications: [
      {
        title: 'Dangerous Races',
        text: 'Unlock and collect new, powerful Cars from sedans to massive trucks - each with a exclusive weapon and special ability.',
        icon: faCarCrash,
      },
      {
        title: 'Multiplayer',
        text: 'Play battle races against people all over the world in a real-time multiplayer game.',
        icon: faGlobeAmericas,
      },
      {
        title: 'Customization',
        text: 'Upgrade and customize your car with items and skins to proudly showcase to your opponents.',
        icon: faFillDrip,
      },
      {
        title: 'Ranking',
        text: 'Climb to the top of the leaderboards in global and local rankings.',
        icon: faMedal,
      },
      {
        title: 'Sound',
        text: "Amazing rock'n'roll soundtrack and an over-the-top announcer on caffeine.",
        icon: faMusic,
      },
      {
        title: 'Mobile',
        text: 'Play for free on Google Play and Apple Store.',
        icon: faMobileAlt,
      },
    ],
  },
];

import React from 'react';

const PresentationVideo: React.FC = () => {
  return (
    <video autoPlay loop muted>
      <source src="./videos/presentation2.mp4" type="video/mp4" />
    </video>
  );
};

export default PresentationVideo;

import React from 'react';

import { AboutPhotoStyle } from './styles';

const AboutPhoto: React.FC = () => {
  return (
    <AboutPhotoStyle>
      <img src="./images/about.jpg" alt="About" />
    </AboutPhotoStyle>
  );
};

export default AboutPhoto;

import styled from '@emotion/styled';

import { Breakpoints, Colors } from '../../styles/theme';

export const AboutStyle = styled.section`
  background-color: ${Colors.black};

  .about-row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const AboutInformationStyle = styled.div`
  padding: 60px;
  padding-right: 40px;
  padding-left: 80px;

  .title {
    margin-bottom: 20px;

    @media (max-width: ${Breakpoints.sm}px) {
      h2 {
        font-size: 20px;
      }
    }
  }

  p {
    color: ${Colors.graySecondary};

    @media (max-width: ${Breakpoints.sm}px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: ${Breakpoints.md}px) {
    padding: 60px 0;
    padding-left: 0;
  }

  @media (max-width: ${Breakpoints.sm}px) {
    padding: 30px 0;
  }
`;

export const AboutPhotoStyle = styled.div`
  img {
    border: none;
    outline: none;
    max-width: 100%;
    vertical-align: middle;
  }

  @media (max-width: ${Breakpoints.sm}px) {
    text-align: center;

    img {
      max-width: 500px;
      width: 100%;
    }
  }
`;

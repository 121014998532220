import styled from '@emotion/styled';

import { Colors, Breakpoints } from '../../styles/theme';

export const RoadWarriorPrivacyStyle = styled.section`
  margin: auto;
  padding: 40px 40px;
  max-width: ${Breakpoints.lg}px;

  h2 {
    margin-bottom: 20px;
  }

  .links {
    margin-bottom: 20px;

    a {
      color: ${Colors.graySecondary};

      &:hover {
        color: ${Colors.gray};
      }
    }
  }
`;

import {
  // faFacebookF,
  faDiscord,
  // faInstagram,
  faYoutube,
  // faTwitter,
  // faTwitch,
} from '@fortawesome/free-brands-svg-icons';

export default [
  // {
  //   name: 'Facebook',
  //   url: 'https://www.facebook.com/rustyharpy',
  //   icon: faFacebookF,
  // },
  {
    name: 'Youtube',
    url: 'https://www.youtube.com/channel/UC8WAm67voHN3JwlITZwSpWA/',
    icon: faYoutube,
  },
  {
    name: 'Finger Blade Discord',
    url: 'https://discord.gg/NQqk2s9P',
    icon: faDiscord,
  },
  {
    name: 'Road Warrior Discord',
    url: 'https://discord.gg/Et4vp8v',
    icon: faDiscord,
  },
  // {
  //   name: 'Instagram',
  //   url: 'https://www.instagram.com/rustyharpy/',
  //   icon: faInstagram,
  // },
  // {
  //   name: 'Twitter',
  //   url: 'https://twitter.com/HarpyRusty',
  //   icon: faTwitter,
  // },
  // {
  //   name: 'Twitch',
  //   url: '',
  //   icon: faTwitch,
  // },
];

import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { GamesSpecificationsStyle } from '../styles';

import GamesSpecificationsItem, { GameSpecificationItem } from './Item';

interface Props {
  specifications: GameSpecificationItem[];
}

const GamesSpecifications: React.FC<Props> = ({ specifications = [] }) => {
  return (
    <GamesSpecificationsStyle>
      <Grid fluid>
        <Row>
          {specifications.map((specification) => (
            <Col
              key={specification.title}
              lg={4}
              sm={6}
              className="specification-col"
            >
              <GamesSpecificationsItem specification={specification} />
            </Col>
          ))}
        </Row>
      </Grid>
    </GamesSpecificationsStyle>
  );
};

export default GamesSpecifications;

import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { GamesStyle } from './styles';

import GamesSlider from './Slider';
import GamesInformation from './Information';
import GamesSpecifications from './Specifications';

import games from './games';

const Games: React.FC = () => {
  return games.map((game) => (
    <GamesStyle
      key={game.id}
      id={'games-' + game.id}
      style={{
        backgroundImage: `url(${game.backgorund})`,
      }}
    >
      <div className="overlay" />
      <Grid fluid className="games-grid">
        <Row>
          <Col lg={12}>
            <GamesSlider images={game.images} logo={game.logo} />
          </Col>
        </Row>

        <Row className="games-row">
          <Col lg={4} md={4}>
            <GamesInformation
              name={game.name}
              resume={game.resume}
              store={game.store}
            />
          </Col>
          <Col md={8}>
            <GamesSpecifications specifications={game.specifications} />
          </Col>
        </Row>
      </Grid>
    </GamesStyle>
  ));
};

export default Games;

import React from 'react';
import Presentation from '../../components/presentation';
import About from '../../components/about';
import Games from '../../components/games';
import Contact from '../../components/contact';
import Header from '../../components/header';

const Home: React.FC = () => {
  return (
    <React.StrictMode>
      <Header />
      <Presentation />
      <About />
      <Games />
      <Contact />
    </React.StrictMode>
  );
};

export default Home;

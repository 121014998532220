import React from 'react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';

import { GamesSliderStyle } from './styles';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const GamesSlider: React.FC<{
  images: string[];
  logo: string;
}> = ({ images, logo }) => {
  return (
    <GamesSliderStyle>
      <div className="slider">
        <AutoplaySlider
          className="aws-btn"
          animation="openAnimation"
          fillParent
          bullets={false}
          organicArrows
          play
          cancelOnInteraction={false}
          interval={6000}
        >
          {images.map((imageUrl) => (
            <div key={imageUrl} data-src={imageUrl} />
          ))}
        </AutoplaySlider>
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
      </div>
    </GamesSliderStyle>
  );
};

export default GamesSlider;

import React from 'react';
import { Grid } from 'react-flexbox-grid';

import { PresentationStyle } from './styles';

import PresentationVideo from './Video';
import PresentationContent from './Content';

const Presentation: React.FC = () => {
  return (
    <PresentationStyle id="home">
      <PresentationVideo />
      <div className="overlay" />
      <Grid>
        <PresentationContent />
      </Grid>
    </PresentationStyle>
  );
};

export default Presentation;

import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { ContactStyle } from './styles';

import ContactForm from './Form';

const Contact: React.FC = () => {
  return (
    <ContactStyle id="contact">
      <Grid>
        <Row center="xs">
          <Col lg={7} md={9}>
            <ContactForm />
          </Col>
        </Row>
      </Grid>
    </ContactStyle>
  );
};

export default Contact;

import React from 'react';
import Privacy from '../../components/roadwarrior/privacy';

const RoadWarriorPrivacy: React.FC = () => {
  return (
    <React.StrictMode>
      <Privacy />
    </React.StrictMode>
  );
};

export default RoadWarriorPrivacy;

export default [
  {
    name: 'Home',
    hash: 'home',
  },
  {
    name: 'About',
    hash: 'about',
  },
  {
    name: 'Finger Blade',
    hash: 'games-2',
  },
  {
    name: 'Road Warrior',
    hash: 'games-1',
  },
  {
    name: 'Contact',
    hash: 'contact',
  },
];

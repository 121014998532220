import styled from '@emotion/styled';

import { Colors } from '../../styles/theme';

export const FooterStyle = styled.footer`
  background-color: ${Colors.blackSecondary};
  display: block;
  color: ${Colors.white};
  text-align: center;
  padding: 30px 0;

  p {
    text-transform: uppercase;
    font-size: 12px;
    margin: 0;
  }
`;

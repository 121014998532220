import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Footer from '../components/footer';

import Home from './home';
import RoadWarriorPrivacy from './roadwarrior/privacy';

import { RoutesNames } from '../router';

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={RoutesNames.home} element={<Home />} />
          <Route
            path={RoutesNames.roadWarriorPrivacy}
            element={<RoadWarriorPrivacy />}
          />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
};

export default App;

export default {
  black: '#050505',
  blackSecondary: '#111111',
  blackTertiary: '#333333',
  blue: '#252C32',
  gray: '#777777',
  graySecondary: '#bbbbbb',
  orange: '#f57a16',
  red: '#d81212',
  white: '#ffffff',
  whiteSecondary: '#dddddd',
};

import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import { AboutStyle } from './styles';

import AboutInformation from './Information';
import AboutPhoto from './Photo';

const About: React.FC = () => {
  return (
    <AboutStyle id="about">
      <Grid fluid>
        <Row className="about-row">
          <Col md={6}>
            <AboutInformation />
          </Col>
          <Col md={6}>
            <AboutPhoto />
          </Col>
        </Row>
      </Grid>
    </AboutStyle>
  );
};

export default About;

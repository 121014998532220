import React, { useState, useEffect } from 'react';
import { Grid } from 'react-flexbox-grid';

import { HeaderStyle } from './styles';

import HeaderMenu from './Menu';

const Header: React.FC = () => {
  const [fixedHeader, setFixedHeader] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const needFixed = window.scrollY > 10;

      if (fixedHeader !== needFixed) setFixedHeader(needFixed);
    });
  }, [fixedHeader]);

  return (
    <HeaderStyle fixed={fixedHeader}>
      <Grid>
        <nav className="navbar">
          <HeaderMenu />
        </nav>
      </Grid>
    </HeaderStyle>
  );
};

export default Header;

import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { HeaderMenuStyle } from './styles';

import menus from './menus';

const HeaderMenu: React.FC = () => {
  const [hase, setHash] = useState('home');
  function onMenuClick(newHash: string) {
    if (newHash !== hase) setHash(newHash);
  }

  return (
    <HeaderMenuStyle>
      <div id="navmenu">
        <ul>
          {menus.map((menu) => (
            <li key={menu.hash}>
              <AnchorLink
                href={`#${menu.hash}`}
                offset={70}
                className={hase === menu.hash ? 'active' : ''}
                onClick={() => onMenuClick(menu.hash)}
              >
                {menu.name}
              </AnchorLink>
            </li>
          ))}
        </ul>
      </div>
    </HeaderMenuStyle>
  );
};

export default HeaderMenu;

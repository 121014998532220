import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import { GamesSpecificationsItemStyle } from '../styles';

export interface GameSpecificationItem {
  title: string;
  text: string;
  icon: IconDefinition;
}

interface Props {
  specification: GameSpecificationItem;
}

const GamesSpecificationsItem: React.FC<Props> = ({ specification }) => {
  return (
    <GamesSpecificationsItemStyle>
      <FontAwesomeIcon icon={specification.icon} />
      <h3>{specification.title}</h3>
      <p>{specification.text}</p>
    </GamesSpecificationsItemStyle>
  );
};

export default GamesSpecificationsItem;

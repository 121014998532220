import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Breakpoints, Colors } from '../../styles/theme';

type HeaderProps = {
  fixed?: boolean;
};

const fixedHeader = css`
  background-color: ${Colors.blackSecondary};
  box-shadow: 0 16px 28px 0 rgba(0, 0, 0, 0.05);
  height: 70px;
  border-bottom: 0;
  padding: 0;
`;

export const HeaderStyle = styled.header<HeaderProps>`
  background-color: transparent;
  width: 100%;
  height: 80px;
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  padding: 10px 0;
  overflow: hidden;

  -webkit-transition: all 0.5s;
  transition: all 0.5s;

  ${(props: HeaderProps) => (props.fixed ? fixedHeader : '')};

  nav {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border: medium;
    border-radius: 0;
    margin: 0;
    padding: 0;
  }
`;

export const HeaderMenuStyle = styled.div`
  margin: auto;
  position: relative;
  display: flex;

  ul {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
      a {
        color: ${Colors.whiteSecondary};
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
        letter-spacing: 1px;
        font-size: 11px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 70px;
        z-index: 1;

        &:hover,
        &.active {
          background: 0 0;
          color: ${Colors.white};
        }

        @media (max-width: ${Breakpoints.md}px) {
          padding: 0 5px;
          font-size: 10px;
        }
      }
    }
  }
`;

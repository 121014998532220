import styled from '@emotion/styled';

import { Colors } from '../../styles/theme';

export const PresentationStyle = styled.section`
  background-image: url(./images/presentation2.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  min-height: 400px;
  position: relative;
  overflow: hidden;
  display: flex;

  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(12, 17, 24, 0.3);
  }

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -0%);
  }
`;

export const PresentationContentStyle = styled.div`
  position: relative;
  text-align: center;
  z-index: 10;

  > img {
    width: 300px;
    height: auto;
  }

  ul {
    margin-top: 30px;

    li {
      display: inline-block;
      margin: 0 12px;

      a {
        display: inline-block;
        color: ${Colors.graySecondary};
        font-size: 17px;
      }
    }
  }
`;
